<template>
  <div class="home">
    <h1>this is home view</h1>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
};
</script>
